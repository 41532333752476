@import 'gridapps-theme';

@import 'bootstrap/scss/bootstrap';

// Import Custom Variables
@import 'variables.scss';

// ng-select
@import '@ng-select/ng-select/scss/default.theme';

// ngx-toastr
@import 'ngx-toastr/toastr-bs5-alert';

// ag grid
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';


@media print {
  .breadcrumb {
    display: none !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

.large-badge {
  font-size: 1em;
  font-weight: normal;
}

.blue-badge {
  background-color: $ga-light-blue !important;
}

.middle-cell {
  vertical-align: middle;
}

.large-tooltip {
  .tooltip-inner {
    max-width: 100%;
    background-color: $ga-dark-red;
  }

  &.show {
    opacity: 1;
  }
}
